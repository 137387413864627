<template>
  <div eagle-form-item="text">
    <v-text-field
      dense
      outlined
      :readonly="readonly"
      :disabled="disabled"
      :maxlength="maxlength"
      :error="!!error"
      :error-messages="error"
      :rules="vuetifyRules"
      :autofocus="focus"
      :type="inputType"
      :counter="!disabled && !readonly"
      v-model="data"
    >
      <template v-slot:append>
        <v-icon
          v-if="password"
          @click="viewPasswordMode = !viewPasswordMode"
        >
          fa fa-eye
        </v-icon>
      </template>
    </v-text-field>

    <v-img
      v-if="thumbnail"
      width="100"
      height="100"
      :src="thumbnail"
    ></v-img>
  </div>
</template>

<script lang="babel" type="text/babel">
import formTextType from '@/components/form/formItemTypes/formTextType'
export default {
  mixins: [formTextType],
  data: () => ({
    viewPasswordMode: false,
  }),
  computed: {
    thumbnail() {
      if(typeof this.formDataItem.thumbnail != 'function') return ''
      return this.formDataItem.thumbnail(this.formInfo, this.$eagleLodash.cloneDeep(this.storeData)) || ''
    },
    password() {
      return this.formDataItem.password === true
    },
    inputType() {
      if(this.viewPasswordMode) return 'text'
      if(this.password) return 'password'
      if(this.formDataItem.inputType) return this.formDataItem.inputType
      return 'text'
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
